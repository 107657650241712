@use 'sass:math';
.c-group-type{
    $cell-height:275px;
    &--cell{
        text-align: center;
        border-right: 0;
        border-bottom: 0;
        position: relative;
        float: left;
        padding: math.div($spacing-base,2);
        width: 50%;
        height: $cell-height;
        font-size: 18px;

        &__3x1{
            width: 100%;
        }

        @media screen and (min-width: $small-width) {
            width: 33.333333333333333333%;
            &__1x2{
                height:$cell-height * 2;
            }
            &__2x2{
                height:$cell-height * 2;
                width: 66.66666666666666%;
            }
            &__3x1{
                width: 66.66666666666666%;
            }
        }
        @media screen and (min-width: $medium-width) {
            width: 20%;
            &__2x1{
                height:$cell-height * 2;
            }
            &__2x2{
                height:$cell-height * 2;
                width: 40%;
            }

            &__3x1{
                width: 60%;
            }
        }

        @for $i from 1 through 12 {
            &-#{$i}{
                width: math.div(100%,$i);
            }

            @media (max-width: ($small-width - 1px)) {
                &-xs-#{$i}{
                    width: math.div(100%,$i) !important;
                }
            }
            @media (max-width: ($medium-width - 1px)) {
                &-sm-#{$i}{
                    width: math.div(100%,$i);
                }
            }
            @media (min-width: $medium-width) {
                &-md-#{$i}{
                    width: math.div(100%,$i);
                }
            }
            @media (min-width: $large-width) {
                &-lg-#{$i}{
                    width: math.div(100%,$i);
                }
            }
        }
    }

    &--box{
        height: 100%;
        position: relative;
        transition: all 150ms ease;
        outline: 0px solid $gray-light;
        outline-offset: 0px;

        padding: $spacing-medium;
        border: 1px solid $gray-light;
        height: 100%;
        color: #000;
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        text-decoration: none;
        background: $white;
        @include flex(column);
        justify-content: space-between;
        
        &:hover{
            outline: 5px solid $gray-light;
            outline-offset: -5px;
        }
    }
    &--image{
        flex-grow: 1;
        position: relative;
        img{
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit:scale-down;
            margin: 0;
        }
        &__full{
            position: static;
            img{
                object-fit: cover;
                transition: all 150ms ease;
                outline: 0px solid $gray-light;
                outline-offset: 0px;
                &:hover{
                    outline: 4px solid $gray-light;
                    outline-offset: -4px;
                }
            }
        }
    }

    &--information{
        text-align: center;
        img{
            max-width: 100%;
            display: block;
            margin: math.div($spacing-base, 2) auto;
            max-height: 52px;
        }
    }

    &--price{
        z-index: 1;
        position: absolute;
        top: 32px;
        right: 15px;
        font-size: 20px;
        display: inline;
        color: $white;
        &::before{
            z-index: -1;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $color-primary;
            width: calc(100% + 10px);
            padding-top: calc(100% + 10px);
            transform: translate(-50%,-50%);
            border-radius: 50%;
        }
    }
}