.site-footer {
	font-family: $font-family--secondary;
	font-size: 14px;
	text-align:center;
	position: relative;
	.container-footer{
		@media screen and (min-width: 3000px) {
			max-width: none;
			padding-left: 2%;
			padding-right: 2%;
		}
	}
	.newsletter{
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		vertical-align: middle;
	}
	.email{
		vertical-align: middle;
	}
	.top-footer{
		background: $color-primary;
		width: 100%;
		max-width: 100%;
		position: relative;
		@media screen and (min-width: $phone-average + 1px) {
			/*&__container{
				@include flex();
				flex-wrap: nowrap;
				align-items: center;
				>*{
					flex-grow: 1;
					margin-left: 30px;
					&:first-child{
						margin-left: 0;
					}
				}
			}

			.mc_embed_signup form,[class*=js-msg-]{
				width: 100%;
			}*/

			
		}
		@media screen and (max-width: $phone-average) {
			.top-footer__logo{
				max-width: 100%;
				float: none;
				max-width: 250px;
				transform: none;
			}
			.top-footer__text{
				width: 100%;
				float: none;
			}
			#mc_embed_signup{
				width: 100%;
				.email,.btn{
					width: 100%;
					float: none;
				}
			}
		}
		&__text{
			//width: calc(50% - 150px);
			//float: left;
			text-align: center;
			font-size: 20px;
			margin: 10px 0;
		}

		&__logo {
			max-height: 80px;
			max-width: 100%;
			//float: left;
			top: 50%;
			//transform: translate(0,-50%);
			@media screen and (min-width: $medium-width) {

			}
		}
		.mc_embed_signup{
			//width: calc(50% - 30px);
			//float: left;
			text-align: right;
		}
		&__container{
			width: 100%;
			max-width: 1320px;
			margin: 0 auto;
			//display: block;
			padding: 30px 12px;
		}
	}
	@media screen and (min-width: $small-width) {
		text-align:left;
	}
	&__background-container{
		background-color: $gray-xxdark;
	}
	.contact-us {
		border-right: none;

		&-label{
			color: $gray-base;
			display:inline-block;
		}
		a[href*="mailto:"] {
			word-break: break-word;
		}
	}
	&__copyright {
		padding-top: $spacing-base-vertical;
		padding-bottom: $spacing-base-vertical;
		margin-top: $spacing-medium;
		background-color: $color-primary;
		font-size: em(12px);
		color: $white;
		text-align: center;
        .container-copyright{
            text-align: center;
            @media screen and (min-width: $medium-width) {
                text-align: left;
            }
        }
        .container-policy{
            text-align: center;
             @media screen and (min-width: $medium-width) {
                text-align: right;
            }
            a{
                @media screen and (min-width: $medium-width) {
                    padding-left: 20px;
                }
            }
        }
	}
	&__disclaimer{
		font-size: 12px;
		color: $gray-light;
		text-transform: uppercase;
	}
	.fa{
		color:$white;
		margin-bottom:5px;
		font-size: 35px;
		margin-right:5px;

		&:hover{
			color: $gray-base;
			cursor:pointer;
			@extend %transition-base;
		}
	}
   h3 {
        font-weight: $font-weight--bold;
        font-family: $font-family--primary;
        font-size: 14px;
        color: $gray-xxlight;
		position: relative;
	   &:after{
		   background: $color-primary;
		   content: ' ';
		   width: 30px;
		   height: 10px;
		   position: absolute;
		   transform: translate(10px, -50%);
		   top: 50%;
	   }
    }
    &__icon-chat{
    	width:100%;
    	text-align:center;
    	margin-bottom: $spacing-large;
    }

    &__logo {
		width: 270px;
	}
	&__logo-paypal img{
		width: 120px;
		margin-top: $spacing-medium;
		@media screen and (min-width: $small-width) {
			float:right;
		}
	}
	&__menu li{
		list-style : none;
		padding-left:0;
		margin-bottom: 15px;
		background-size:cover;
		width: inherit;

		a{
			color:$white;
			text-transform: uppercase;
		}
	}
    .mc_embed_signup{
       
        form{
            padding: 0; 
        }
        input[type=submit]{
            border: 0;
            text-transform: uppercase;

        }
        input.email{
            display: inline-block;
            padding: 10px;
            background-color: $white;
            border-radius: 0;
            margin: 0; 
			//width: 50%;
			flex-grow: 1;
		}
		
		#mc_embed_signup_scroll{
			display: flex;
			flex-wrap: nowrap;
			margin: 10px 0;
			
		}
    }
	p {
		color: $gray-xxlight;
		margin-bottom:20px;
		line-height: 1.5;
    }
    .subscription {
        button {
            width: 100%;
        }
        p {
            margin-bottom: 0;
        }
        .title {
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'Poppins',"Helvetica Neue",Helvetica,Arial,sans-serif;
            font-size: 20px;
            color: #FFF;
        }
    }
	&__section {
		padding-left: $spacing-medium-horizontal;
		padding-right: $spacing-medium-horizontal;
		margin-bottom: $spacing-medium-vertical;
		margin-bottom: $spacing-large-vertical;
		/*&:nth-child(2){
			width: 50%;
			@media screen and (max-width: $medium-width) {
				width: 100%;

			}
		}*/
		@media screen and (min-width: $medium-width) {
			margin-bottom: $spacing-xxlarge-vertical;

		}
	
	}
    // Work around for same height section
	@media screen and (min-width: $medium-width) {
		
		&__section {
			display: table-cell;
			float: none;
		}

		._table {
			display: table;
			width: 100%;
			margin-bottom:30px;
		}
	}

}
@media screen and (min-width: $phone-average) {
	#mc_embed_signup_scroll{
		//margin-top: -20px!important;
	}
	/*.site-footer .top-footer__text {
		text-align:right;
	}*/
}