main {
   top: 150px;
   position: relative;
   margin-bottom: 144px;
    @media screen and (min-width: $medium-width) {
        top: 0px;
         margin-bottom: inherit;
    }
    .container-home--content{
        margin-top: 35px;
        @media screen and (min-width: $medium-width) {
            margin-top: 0;
            margin-top: 100px;
        }
         @media screen and (min-width: $medium-width) {
            margin-top: 0;

        }

    }
    .fullwidth {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0; 
    }
     .mobile-overlay{
        background-color: rgba(0,0,0,.7);
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;
    }
    
}
.container-partial--latest-promo {
  position: relative;
  padding: 25px!important;
  @media screen and (max-width:$small-width) {
    padding: 60px!important;
  }
  .slick-prev{
    transform: translate(-137px,-50%)!important;
  }
  .slick-next{
    transform: translate(137px,-50%) scaleX(-1)!important;
  }
  @media screen and (min-width: $medium-width) {
    top: -2px;
    margin-bottom: inherit;
  }
  .slick-track{
    min-height: 259px;
  }
  .home-promo-container{
      display: inline-block;
      /*position: absolute;
      width: 66.666%;
      right: 66.666%;
      transform: translateX(-35px);
      &:nth-child(2n){
        right:33%;
      }*/
      //@media screen and (max-width: $container-width ) {
        transform: none;
        width: 100%;
        padding: 0 30px;
        left: 0;
      //}
      @media screen and (max-width: $phone-average ) {
        width: 100%;
        padding: 0;
        float: none;
        position: relative;
      }
      
    img{
      width: 100%;
      padding: 0!important;
      position: static!important;
      top: inherit!important;
      left: inherit!important;

    }
    &--weathertech{
      :hover{
        cursor: pointer;
      }
    }
  }
  .partial-title{
    &__home-promo {
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      padding: 0;
      color: #fff;
      width: calc(100%/3);
      max-width: 375px;
      left: calc(50% - 653px);
      text-transform: uppercase;
      font-size: 54px;
      line-height: 1;
      @media screen and (max-width: $container-width ) {
        margin: auto;
        position: relative;
        max-width: 100%;
        //height: 0px;
        min-height: 0;
        text-align: center;
        left: 0;
        width: 100%;
        top: 0px;
        transform: initial;
        font-size: 40px;
      }
      /*@media screen and (max-width: $phone-average ) {
        margin-bottom: 40px!important;
      }*/
    }

  }

  .slick-slide{
    position: relative;
    width: 100%;
    padding-top: 60px;
  }
  .slick-slider{
    max-width: 100%;
    width:1350px;
    position: relative;
    margin: 0 auto;
    &.js-promo--autoload{
      width: 100%;
      margin-right: 0;
      padding:0 5% 0 45%;
      .slick-arrow.slick-prev{
        left: 160px !important;
      }
      .slick-arrow.slick-next{
        right: 160px !important;
      }
      @media screen and (max-width: $container-width ) {
        padding: 0;
        .slick-arrow.slick-prev,.slick-arrow.slick-next{
          display: none !important;
        }
      }
    }
    @media screen and (max-width: $container-width ) {
      top: 25px;
    }
  }
  .container-promo img {
    width: 100%;
    padding: 10px;
  }

}