    .container-partial {
        padding: 10px;


        @media screen and (min-width:$medium-width) {
            padding: 60px;
        }
        .btn{
          margin-top: 15px;
        }
        &--best-sellers {
            background: #fff;

            .container-brand-part-type {
                text-align: center;
                border-right: 0;
                border-bottom: 0;
                padding: 40px;
                 .container-img {
                     height: 150px;
                }
               
                object,img {
                    max-width: 100%;
                    display: block;
                    margin: auto;
                    max-height: 150px;

                    &.brand-part-type-logo {
                        max-height: 52px;
                    }
                }
                &:hover {
                    cursor: pointer;
                }


                .container-price-quantity{
                    .availability{
                        display: none;
                    }
                    .quantity-control{
                        display: none;
                    }
                    .moreinfo-button{
                        display: none;
                    }
                }
            }
            h2 {
                 @media screen and (min-width: $medium-width) {
                    padding-top: 40px;
                }
            }
        }
         &--help{
            background-color: $shark;
            width:100%;
            z-index:400;
            color: #fff;
            display:none;
            top: 113px;
            padding: 20px;
            position: fixed;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            @media screen and (min-width:$medium-width) {
                position: fixed;
                top: 45px;
                width: 250px;
                height: auto;
            }
            .btn {
              width: 100%;
              margin-top: 15px;
            }
            p{
                font-size: 14px;
            }
            h2{
                font-size: 1.5em;
            }
            .button-back-menu{
                display:block;
                float: right;
                bottom: 5px;
                position: relative;
                cursor:pointer;
            }
            .fa-arrow-circle-o-left {
                font-size: 25px;
                vertical-align: middle;
                 @media screen and (min-width:$medium-width) {
                    display: none;
                }
            }
            .close-help{
                display: none;
                font-style: normal;
                @media screen and (min-width:$medium-width) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    right: 0;
                }
                &:before{
                    font-size: 20px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .text:first-of-type {
                padding-top: 10px;
            }
            .title {
                font-size: 16px;
               //border-bottom: 1px solid #878787;
            }
        }
        &--my-profile{
            background-color: $shark;
            color: #fff;
            padding: 10px 20px 20px!important;
            box-shadow: inset -17px 20px 20px -20px #000;
            .title {
                font-size: 20px;
                border-bottom: 1px solid $gray-base;
                margin-bottom: 10px;
            }

            a, button{
                color: #FFF;
                font-family: $font-family--secondary;
                background-color: $color-primary;
                cursor: pointer;
                position: relative;
                padding: 12px 10px;
                text-decoration: none;
                font-size: .875em;
                font-weight: 700;
                text-align: center;
                vertical-align: middle;
                border-radius: 50px;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                overflow: hidden;
                display: inline-block;
                width: 100%;
                margin: 10px 0;
            }
        }
        &--my-garage{
            background-color: $shark;
            width:100%;
            z-index:400;
            color: #fff;
            display:none;
            top: 150px;
            position: fixed;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            padding: 10px 20px 20px!important;
            top: 113px;
             @media screen and (min-width:$medium-width) {
                top: 45px;
                width: 250px; 
                height: auto;
            }

            .addvehicule{
                width: 100%;
                margin-top: 10px;
            }
            .add-a-vehicule{
                margin-top: 15px;
            }
            .button-back-menu{
                display:block;
                float: right;
                bottom: 5px;
                position: relative;
                cursor:pointer;
            }
            .fa{ 
                font-size:16px;
            }
            .fa-arrow-circle-o-left{
                font-size:25px;
                vertical-align: middle;
                 @media screen and (min-width:$medium-width) {
                    display: none;
                }
            }
            .close-my-garage{
                display: none;
                font-style: normal;
                @media screen and (min-width:$medium-width) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    right: 0;
                }
                &:before{
                    font-size: 20px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .removevehicule {
                float:right;
            }
            select, .select2-selection--single {
                background-color: $white;
                width:100%;
                margin-bottom: 20px;
                color: $gray-base;
                font-family: $font-family--secondary;
                font-size: 13px;
                padding: 16px 10px;
                height: inherit;
                 @media screen and (min-width:$medium-width) {
                     padding: 8px 10px;
                    margin-bottom: 5px;
                }
            }
            .text-my-garage {
                margin-bottom:10px;
            }
             .title {
                font-size: 20px;
            } 
          
            .vehicules {
                font-family: $font-family--secondary;
                font-size: 13px;
                line-height: 20px;
                 padding-top: 10px;
                border-top: 1px solid $gray-base;
                .vehicule-data:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
                .vehiculerow {
                    margin-bottom: 5px;
                    font-size: 14px;
                    @media screen and (min-width:$medium-width) {
                        margin-bottom: 0;
                        font-size: 12px;
                    }


                }
            }

           
        }
        &--latest-promo {
            background: #363636;
            button {
                bottom: initial;
                background-color: transparent;
                &.slick-arrow {
                    position: absolute;
                    top: 45%;
                    cursor: pointer;
                    outline: 0;
                    display: none!important;
                    @media screen and (min-width:$medium-width) {
                        display: block!important;
                    }
                  @media screen and (max-width: $medium-large-width){
                    display: inline-block!important;
                  }
                  
                }
                &.slick-prev {
                    left: -70px!important;
                }
                &.slick-next {
                    right: -70px!important;

                }
            }
            .container-promo img {
                max-width: 100%;
                padding: 10px;
            }
            .description{
                color: $white;
            }
        }
        &--order-detail{
            background-color: $white;
            padding: 60px 0;
            font-family: $font-family--secondary;
            h2{
                color: $black;
                font-family: $font-family--primary; 
            }
            .item-labels{
                border-bottom: 1px solid $gray-light;
                font-weight: bold;
                text-align: center;
                .item-label{
                    padding: 10px;
                }
            }
            .item{
                @media screen and (min-width:$medium-width) {
                    font-size: 14px;
                }
                @media screen and (min-width: $medium-width) {
                    font-size: 16px;
                }
            }
            .link-back{
                text-align: center;
                @media screen and (min-width:$medium-width) {
                    text-align: right;
                }
            }
            .order-detail{
                .row{
                    padding: 10px;
                }
                .border-bottom{
                     @media screen and (min-width:$medium-width) {
                        border-bottom: 1px solid $gray-xlight;
                     }
                }
                &__label{
                    text-align: center;
                    @media screen and (min-width: $medium-width) {
                        text-align: right;
                    }
                }
                 &__info{
                    text-align: center;
                    @media screen and (min-width: $medium-width) {
                        text-align: left;
                    }
                }
            }
        }
        &--order-history{
            background-color: $white;
            padding: 60px 0;
            font-family: $font-family--secondary; 
           
            .dataTables_wrapper{
                .dataTables_paginate{
                    text-align: center;
                    float: none;
                    .paginate_button{
                        &.current{
                        border: 0!important;
                        background: transparent!important;
                        font-weight: bold;
                        margin-top: 20px;
                        }
                        &.previous, &.next{
                           color: transparent!important; 
                           display: none;
                        }
                        &.previous:not(.disabled), &.next:not(.disabled), &.previous:not(.disabled):hover, &.next:not(.disabled):hover{
                            width: 30px;
                            background-repeat: no-repeat;
                            background-size: 100%;
                            display: inline-block;
                            background-position: center;

                            &:hover{
                                cursor: pointer;
                            }
                        }
                         &.next{
                             transform:scaleX(-1);
                         }
                    }
                    
                }
            }
            .link-back{
                text-align: center;
                @media screen and (min-width:$medium-width) {
                    text-align: right;
                }
            }
            .order-history--table{
                font-size: 14px;
                 @media screen and (min-width:$medium-width) {
                    font-size: 16px;
                 }
                &.no-footer{
                    border-bottom: 1px solid $gray-light;
                }
                th, tr{
                    border-bottom: 1px solid $gray-light;
                }
             
            }
           
        }
        &--our-brands {
            overflow: hidden;
            background-position: -600px -200px;
            background-repeat: no-repeat;
            background-color: $white;
            padding: 20px 0;
            &--title{
                background-color: $gray-wlight;
                font-size: 53px;
                font-weight: bold;
                text-transform: uppercase;
                border-left: 10px solid $color-primary;
                width: 350px;
                padding: 30px;
                order: 2 !important;
                color: $black;
            }
            .container-brands {
              padding: 0 10px;
                margin-left: 0;
                margin-right: 0;

                    .slick-prev{
                      transform:  translate(61px,-50%)!important;
                    }
                    .slick-next{
                      transform:  translate(-61px,-50%) scale(-1,1)!important;
                    }

                  .slick-list{
                    width: 1350px;
                    max-width: calc(100% - 80px);
                    margin: 10px auto;
                    @media screen and (min-width:$medium-width) {
                        max-width: calc(100% - 223px);
                    }
                    @media screen and (max-width: $phone-average ) {
                      max-width: 100%;
                    }

                  }
                .container-logo{
                  width: calc(100%/2);
                  float: left;
                  @media screen and (min-width:$small-width) {
                    width: calc(100%/5);
                  }
                  @media screen and (min-width:$medium-width) {
                    width: calc(100%/7);
                  }
                }

                .no-button{
                    .slick-list{
                        max-width: inherit !important;
                        width: 100%;
                    }
                    .slick-arrow{
                        display: none !important;
                    }
                }
            }

            .container-logo {
                text-align: center;
                padding: 5px;
                min-height: 100px;
                img {
                    width: 90%;
                    @extend %transition-base;
                    &:hover {
                        @media screen and (min-width: $medium-width) {
                            transform:scale(1.2,1.2);
                        }
                       
                    }
                }
            }
        }
          .slick-arrow.slick-prev {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            @media screen and (max-width: $medium-large-width){
              display: inline-block!important;
            }
          }
            .slick-arrow.slick-next {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%) scale(-1,1);
              @media screen and (max-width: $medium-large-width){
                display: inline-block!important;
              }
          }
            h2,h1 {
              text-align: center;
              font-family: "Open Sans",sans-serif;
              color: #000000;
              letter-spacing: 3px;
              padding-bottom: 20px;
              font-weight: lighter;
              font-size: 32px;

            .color-secondary {
                color: $color-secondary;
            }
            &.partial-title{
                font-weight: bold;
            }
          
        }
        h3 {
            text-align: center;
            font-family: $font-family--secondary;
            letter-spacing: 1px;
            color: $black;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            min-height: 35px; 
            margin-top:10px;
            margin-bottom: 10px;
        }
        h4 {
            color: $color-secondary;
            font-size: 22px;
            text-transform: uppercase;
        }
        &--search-engine{
            h4{
              font-size: 29.5px;
              text-transform: none;
              font-weight: bold;
              span{
                font-weight: normal;
              }
            }
        }
        &--subscription{
            background-color: $shark;
            width:100%;
            z-index:400;
            color: #fff;
            display:none;
            top: 113px;
            position: fixed;
            padding: 10px 20px 20px 20px!important;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
             @media screen and (min-width:$medium-width) {
                position: fixed;
                top: 45px;
                width: 250px; 
                height: auto;
            }
            .button-back-menu{
                float: right;
                position: relative;
                bottom: 5px;
                cursor:pointer;
            }
            .close-subscription{
                display: none;
                @media screen and (min-width:$medium-width) {
                    display: block;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    right: 0;
                }
                &:before{
                    font-size: 20px;
                }
                &:hover{
                    cursor: pointer;
                }
            }
             .fa-arrow-circle-o-left{
                font-size:25px;
                vertical-align: middle;
                 @media screen and (min-width:$medium-width) {
                    display: none;
                }
            }

            .btn{
                width: 100%;
            }
             .fa-close{
                position: relative;
                 @extend %transition-base;
                 display: none;
                  @media screen and (min-width:$medium-width) {
                    display: block;
                  }
                &:hover{
                    cursor: pointer;
                    color: $color-primary;
                    @extend %transition-base;
                }
            }

            p{
                font-family: $font-family--secondary;
                font-size: 13px;
                a{
                    font-weight: bold;
                    color: $color-primary;
                }
                &.text{
                    border-top: 1px solid $gray-base;
                    padding-top: 10px; 
                }
            }
            .title{
                font-size: 20px;
            }

        }
        &--tools-and-accessories {
            z-index: 99;
            position: absolute;
            background-color: $shark;
            width: 100%;
            display: none;
            background-repeat: no-repeat;
            background-size: contain;
            border-bottom: 10px solid $color-primary;
            padding-bottom: 20px;
            top: 150px;
             @media screen and (min-width:$medium-width) {
                top: 200px;
            }
            @media screen and (min-width: $medium-width) {
                top: inherit;
            }
             &.active{
                display: block;
            }
            button{
                margin-top: 0;
                text-transform: uppercase; 
                padding: 10px;
                &.btn{
                    width: 100%;
                    @media screen and (min-width:$medium-width) {
                        width: inherit;
                        float: right;
                    }
                }
            }
            .container-search-field{
                @media screen and (min-width: $medium-width) {
                     float: right;
                }
            }
            .container-search-full{
                background-image: url('/dist/images/gear.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;
                height: 165px;
                border-top: 5px solid #1e1e20;
                text-align: center;
                @media screen and (min-width:$medium-width) {
                    text-align: left;
                    
                }
                @media screen and (min-width: $medium-width) {
                    border-left: 10px solid #1e1e20;
                    border-top: 0;
                    height: 135px;
                }
                button{
                    width: 130px;
                }
                .subtitle:nth-of-type(2){
                    font-size: 24px;
                }
            }
            .container-search-keyword{
                 padding: 11px;
                .subtitle{
                    padding-top: 10px;
                }
            }
            .container-search-tool-keyword{
                background-color: #484848; 
                border: 10px solid #1e1e20;
                margin: 15px 0 50px;
            }
            .container-subcategory{
                display: none;
                font-family: $font-family--secondary;
                font-size: 14px;
                margin-top: 20px;
                a{
                    color: $gray-base;
                }
                .subcategory{
                    margin-bottom: 5px;
                    font-weight: bold;
                    color: $white;
                }
            }
            .divider{
                border-top: 5px solid #1e1e20;
                margin: 5px 0;
            }
            .form-group{
                text-align: center;
                 @media screen and (min-width:$medium-width) {
                    text-align: left;
                 }
            }
            h2{
                color: $white;
                text-transform: uppercase;
                font-size: 40px;
            }
            input[type=text]{
                background-color: $white;
                padding: 10px;
                width: 100%;
                margin-right: 10px;
                font-family: $font-family--secondary;
                font-size: 14px;
                margin-bottom: 10px;
                @media screen and (min-width:$medium-width) {
                    width: 220px;
                }
            }
            .input--keyword{
               background-color: $white; 
               width: 100%;
               font-family: $font-family--secondary; 
               font-size: 14px;
               @media screen and (min-width: $medium-width) {
                    width: 50%;
                }
            }
            &__list{
            text-align: center;
                @media screen and (min-width: $medium-width) {
                    text-align: left;
                }
            }
            .subtitle{
                color: $white;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 15px;
                display: inline-block;
                font-family: $font-family--secondary;
            }
            .tools-category{
                font-family: $font-family--secondary;
                border-bottom: 1px solid $gray-base;
                padding: 8px;
                &:last-of-type{
                    border: 0;
                }
                &:hover{
                    cursor: pointer;
                }
            }
            .title{
                color: $white; 
                
            }
            .tools-category{
                padding: 10px;
                font-family: $font-family--secondary; 
                @extend %transition-base;
                font-size: 14px;
                &:not(:last-of-type){
                    border-bottom: 1px solid $gray-base;
                }
                &.active{
                    background-color: $gray-dark;
                    color: $white; 
                }
                &:after{
                    content: "";
                    float: right;
                }
                &:hover{
                    background-color: $gray-dark; 
                    color: $white;
                    cursor: pointer;
                    @extend %transition-base;
                }
            }
            ul{
               list-style: none;
               li{
                    color: $white;
                    font-family: $font-family--secondary;
                    font-size: 14px; 
                   h5{
                        padding-top: 10px;
                   }
               }
            }
          
        
        }
    
    }